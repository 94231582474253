import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import {
  MDBNavbar,
  MDBCheckbox,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBTabs,
  MDBTabsItem,
  MDBValidationItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRadio,
  MDBBtnGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBTypography,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBInputGroup,
  // MDBPagination, MDBPaginationItem, MDBPaginationLink ,
  // MDBCarousel,
  // MDBCarouselInner,
  // MDBCarouselItem,
  // MDBCarouselElement,
  // MDBCarouselCaption,
  MDBCardImage,
  MDBAccordion,
  MDBAccordionItem,
} from 'mdb-react-ui-kit';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { isEmpty } from 'lodash';
import './styles.css';

function ReviewModal(props) {
  const {
    basicModal2,
    setBasicModal2,
    closeModal2,
    clearCartItems,
    checkout_items,
    extraChangeCheckOut,
    attributeChangeCheckOut,
    handleOptionSelectionCheckout,
    decrementCounter2,
    incrementCounter2,
    removeFromCart,
    totalOrders,
    contactChange,
    validPhone,
    actualIsCheckoutAllowed,
    toggleOrderNotAllowed,
    validateForm,
    setToggleTwoModal,
    RenderMobileDineInTakeoutControl,
    totalPrice,
    final_contact,
    tableNumbers,
    final_table,
    tableChange,
    validTable,
    final_notes,
    notesChange,
    isMobileView,
    isDineIn,
    toggleTwoModal,
    setCheckOutItems,
    setTotalOrders,
    calculatePrice,
    userProfile,
    orderContext,
    isGuest,
    guestInfo,
    setGuestInfo,
    guestInfoValidation,
    setGuestInfoValidation,
    setAttributesRequiredModal,
  } = props;

  useEffect(() => {
    if (!basicModal2) {
      setGuestInfoValidation({});
    }
  }, [basicModal2]);

  const parentSelected = (checkoutitem_index, belongsto) => {
    const exists = checkout_items[checkoutitem_index]?.orders?.[7]?.find((a) => {
      return a?.options?.find?.((b) => {
        return b._id == belongsto;
      });
    });

    const option = exists?.options?.find?.((b) => {
      return b._id == belongsto;
    });

    return option?.selected == 'Y';
  };

  const adjustCount = (type, checkoutitem_index, variationindex, optionIndex) => {
    let item = checkout_items[checkoutitem_index].orders[7][variationindex].options[optionIndex];
    if (item) {
      item.selected = 'Y';
      if (type == 'add') {
        item.quantity = item.quantity + 1;
      } else if (type == 'minus') {
        const newqty = item.quantity - 1 < 0 ? 0 : item.quantity - 1;
        item.quantity = newqty;
        if (newqty == 0) {
          delete item.selected;
        }
      }
    }

    setCheckOutItems([...checkout_items]);
    setTotalOrders([...checkout_items]);
    localStorage.setItem('orders', JSON.stringify(checkout_items));
  };

  const adjustCountRadio = (
    checkoutitem_index,
    variationindex,
    variation,
    option,
    optionIndex,
    optionx,
    optionxIndex,
    variationx,
    variationxindex2,
  ) => {
    let item = checkout_items[checkoutitem_index].orders[7][variationxindex2].options[optionxIndex];

    if (item) {
      //Clear items in this category
      checkout_items[checkoutitem_index].orders[7][variationxindex2].options.map((itemA) => {
        if (item._id != itemA._id) {
          delete itemA.selected;
        }
        itemA.quantity = 0;
      });

      if (item.selected == 'Y') {
        item.selected = '';
        item.quantity = 0;
      } else {
        item.selected = 'Y';
        item.quantity = 1;
      }
    }

    setCheckOutItems([...checkout_items]);
    setTotalOrders([...checkout_items]);
    localStorage.setItem('orders', JSON.stringify(checkout_items));
  };

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email || '')) {
      return true;
    }
    return false;
  };

  const proceedCheckout = () => {
    //check if required are still selected
    let passed = true;

    checkout_items.map((item) => {
      const attributes = item.orders[7];

      attributes?.map?.((attr) => {
        if (attr.attributesRequired) {
          //find option selected
          const selected = attr.options.find((o) => o.selected == 'Y');
          if (!selected) {
            passed = false;
            return;
          }

          //check if has attributes
          if (selected.isconnectedto) {
            //get attributes and make sure they are selected
            selected.isconnectedto.map((attr2) => {
              const options = attributes.find((a) => a._id == attr2).options;

              const hasSelected = options.find((o) => o.selected == 'Y');
              if (!hasSelected) {
                passed = false;
              }
            });
          }
        }
      });
    });

    if (!passed) {
      setAttributesRequiredModal(true);
      return;
    }

    if (isMobileView) {
      if (!actualIsCheckoutAllowed()) {
        toggleOrderNotAllowed();
        return;
      }
    }

    let conditionPassed = final_contact.length > 3;

    if (isDineIn) {
      conditionPassed = conditionPassed == true && !isEmpty(final_table);
    }

    if (isGuest) {
      conditionPassed =
        conditionPassed == true &&
        !isEmpty(guestInfo.firstname) &&
        !isEmpty(guestInfo.lastname) &&
        !isEmpty(guestInfo.email);

      conditionPassed = conditionPassed == true && validateEmail(guestInfo.email);
    }

    if (!conditionPassed) {
      if (final_contact.length < 3) {
        validateForm('phone');
      }
      if (final_table.length < 1 && isDineIn) {
        validateForm('table');
      }
      let errorFields = {};
      if (!guestInfo.firstname) {
        errorFields = {
          ...errorFields,
          firstname: true,
        };
      }
      if (!guestInfo.lastname) {
        errorFields = {
          ...errorFields,
          lastname: true,
        };
      }
      if (!guestInfo.email || !validateEmail(guestInfo.email)) {
        errorFields = {
          ...errorFields,
          email: true,
        };
      }
      setGuestInfoValidation({ ...errorFields });
    } else {
      setBasicModal2(!basicModal2);
      setTimeout(() => {
        setToggleTwoModal(!toggleTwoModal);
      }, 400);
    }
  };

  // console.log('guestInfo', guestInfo);
  // console.log('orderContext', orderContext);
  // console.log('checkout_items', checkout_items);

  return (
    <MDBModal
      className="desktop_checkout_page previewmodal"
      show={basicModal2}
      setShow={setBasicModal2}
      tabIndex="-1"
    >
      <MDBModalDialog className="detail_modal">
        <MDBModalContent>
          {basicModal2 ? (
            <>
              <MDBModalHeader className="modalcart">
                {/* <MDBModalTitle>Confirm Order</MDBModalTitle> */}
                <MDBBtn className="btn-close" color="none" onClick={closeModal2}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody className="modalcartbody">
                <>
                  {/* DELETE BUTTON */}
                  {typeof localStorage.getItem('orders') !== 'undefined' &&
                  localStorage.getItem('orders') !== null ? (
                    <MDBRow className="detail_tab_clearcart">
                      <MDBCard
                        alignment="center"
                        className="detail_tab_checkout_card2 no_border no_box_shadow"
                      >
                        <MDBCardBody>
                          <MDBCardText>
                            <MDBCol>
                              <MDBBtn
                                onClick={() => clearCartItems()}
                                outline
                                color="black"
                                className=""
                                type="button"
                              >
                                Fjern alle varer
                              </MDBBtn>
                            </MDBCol>
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBRow>
                  ) : null}
                  <div className="itemsCont mt-2">
                    {checkout_items?.map?.((checkoutitem, checkoutitem_index) => (
                      <MDBRow className="mt-2">
                        <MDBRow>
                          <MDBCol size="4" className="confirm_order_image">
                            <img
                              src={checkoutitem.orders[4]}
                              className="no_border img-thumbnail add_cart_image"
                              alt="..."
                              style={{}}
                            />
                          </MDBCol>

                          <MDBCol size="8" className="confirm_order_div">
                            <MDBRow className="confirm_order_image_row">
                              <MDBCard className="no_box_shadow no_border" alignment="">
                                <MDBCardBody className="confirm_order_image_cardbody">
                                  <MDBCardText className="confirm_order_title">
                                    {' '}
                                    {checkoutitem.orders[1]}{' '}
                                  </MDBCardText>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBRow>
                            <MDBRow className="confirm_order_title1x">
                              <MDBCard className="no_box_shadow no_border" alignment="">
                                <MDBCardBody className=" ">
                                  <MDBCardText className=" confirm_order_title1">
                                    {' '}
                                    {checkoutitem.orders[3]}
                                  </MDBCardText>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBRow>
                            <MDBRow className="confirm_order_title2x">
                              <MDBCard className="no_box_shadow no_border" alignment="">
                                <MDBCardBody className=" ">
                                  <MDBCardText className=" confirm_order_title2">
                                    {' '}
                                    DKK {checkoutitem.orders[2].toFixed(2)}
                                  </MDBCardText>
                                </MDBCardBody>
                              </MDBCard>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>

                        <MDBRow className="detailTabCartExtra"></MDBRow>
                        {/* <hr style={{marginTop:"40px",marginBottom:"0"}}/> */}

                        {checkoutitem?.orders?.[6]?.length > 0 ||
                        checkoutitem?.orders?.[7]?.length > 0 ? (
                          <>
                            <MDBAccordion initialActive={0} className="p-0">
                              <MDBAccordionItem
                                collapseId={1}
                                headerTitle="Tilvalg"
                                className="my-2"
                              >
                                <>
                                  {/* EXTRAS */}
                                  {checkoutitem?.orders?.[6]?.map?.((extra) => (
                                    <>
                                      <MDBRow className="detailTabCartExtraItems mt-2">
                                        <MDBCol className="detailTabCartExtraItemsCol">
                                          <MDBCard>
                                            <MDBCardBody className="detailTabCartExtraItemsBody">
                                              <MDBCardText className="detailTabCartExtraItemsBodyText">
                                                {extra.name}

                                                <span className="toggle_holder">
                                                  <MDBBtn
                                                    onClick={(e) => {
                                                      extraChangeCheckOut(
                                                        e.target,
                                                        'increase',
                                                        extra._id + 'index' + checkoutitem_index,
                                                      );
                                                    }}
                                                    outline
                                                    color="black"
                                                    className="extraCounters extraCountersButton"
                                                    type="button"
                                                  >
                                                    <MDBIcon
                                                      fas
                                                      icon="plus-circle"
                                                      // size="2x"
                                                      className="detailTabCartExtraItemsBodyTextBtn"
                                                    />
                                                  </MDBBtn>

                                                  <MDBInput
                                                    id={extra._id + 'index' + checkoutitem_index}
                                                    min="1"
                                                    max="99"
                                                    type="number"
                                                    value={extra.quantity}
                                                    className="extraCountersInputNew"
                                                    placeholder=""
                                                  />
                                                  <MDBBtn
                                                    onClick={(e) => {
                                                      extraChangeCheckOut(
                                                        e.target,
                                                        'decrease',
                                                        extra._id + 'index' + checkoutitem_index,
                                                      );
                                                    }}
                                                    outline
                                                    color="black"
                                                    className="extraCounters extraCountersButton"
                                                    type="button"
                                                  >
                                                    <MDBIcon
                                                      fas
                                                      icon="minus-circle"
                                                      // size="2x"
                                                      className="detailTabCartExtraItemsBodyTextBtn"
                                                    />
                                                  </MDBBtn>
                                                </span>

                                                <h5 className="extraPrice">DKK {extra.price} </h5>
                                              </MDBCardText>
                                            </MDBCardBody>
                                          </MDBCard>
                                        </MDBCol>
                                      </MDBRow>
                                    </>
                                  ))}

                                  {/* ATTRIBUTES */}
                                  {checkoutitem?.orders?.[7]?.map?.((variation, variationindex) =>
                                    !variation.hasOwnProperty('belongsto') ? (
                                      <>
                                        <MDBRow className="detailTabCartExtra mt-2">
                                          <MDBCol className="onePointFiveRem">
                                            <MDBCard className="no_box_shadow">
                                              <MDBCardBody className="detailTabCartExtraBody detailTabCartVariationBody">
                                                <MDBCardText className="variationtitle">
                                                  {variation.name}
                                                  {variation.attributesRequired && (
                                                    <span className="requiredTxt">(Required)</span>
                                                  )}
                                                </MDBCardText>
                                              </MDBCardBody>
                                            </MDBCard>
                                          </MDBCol>
                                        </MDBRow>
                                        <div className=" variationCard3">
                                          {variation?.options?.map?.((option, optionIndex) => (
                                            <MDBRow className="detailTabCartVariantItems detailTabCartVariantItemsX ">
                                              <MDBCol>
                                                <MDBCard className="no_box_shadow variationCard5">
                                                  <MDBCardBody className="detailTabCartVariantItemsBody variationCard6">
                                                    <MDBCardText className="detailTabCartVariantItemsBodyText  variationCard4">
                                                      <MDBRow className="attribLabel">
                                                        {/* ISMULTIPLE TRUE */}
                                                        {variation.isMultiple == true ? (
                                                          <>
                                                            {/* ISMULTIPLE TRUE */}
                                                            <MDBRow className="detailTabCartExtraItems">
                                                              <MDBCol className="detailTabCartExtraItemsCol">
                                                                <MDBCard>
                                                                  <MDBCardBody className="detailTabCartExtraItemsBody p-0">
                                                                    <MDBCardText className="detailTabCartExtraItemsBodyText">
                                                                      {/* <MDBRow> */}
                                                                      {option.name}
                                                                      <span className="toggle_holder">
                                                                        <MDBBtn
                                                                          onClick={(e) => {
                                                                            adjustCount(
                                                                              'add',
                                                                              checkoutitem_index,
                                                                              variationindex,
                                                                              optionIndex,
                                                                            );
                                                                          }}
                                                                          outline
                                                                          color="black"
                                                                          className="extraCounters extraCountersButton"
                                                                          type="button"
                                                                        >
                                                                          <MDBIcon
                                                                            fas
                                                                            icon="plus-circle"
                                                                            // size="2x"
                                                                            className="detailTabCartExtraItemsBodyTextBtn"
                                                                          />
                                                                        </MDBBtn>
                                                                        <MDBInput
                                                                          value={option.quantity}
                                                                          min="1"
                                                                          max="99"
                                                                          type="number"
                                                                          className="extraCounters"
                                                                          placeholder=""
                                                                        />
                                                                        <MDBBtn
                                                                          onClick={(e) => {
                                                                            adjustCount(
                                                                              'minus',
                                                                              checkoutitem_index,
                                                                              variationindex,
                                                                              optionIndex,
                                                                            );
                                                                          }}
                                                                          outline
                                                                          color="black"
                                                                          className="extraCounters extraCountersButton"
                                                                          type="button"
                                                                        >
                                                                          <MDBIcon
                                                                            fas
                                                                            icon="minus-circle"
                                                                            // size="2x"
                                                                            className="detailTabCartExtraItemsBodyTextBtn"
                                                                          />
                                                                        </MDBBtn>
                                                                      </span>
                                                                      {/* </MDBRow> */}
                                                                      <h5 className="extraPrice">
                                                                        DKK {option.additionalPrice}{' '}
                                                                      </h5>
                                                                    </MDBCardText>
                                                                  </MDBCardBody>
                                                                </MDBCard>
                                                              </MDBCol>
                                                            </MDBRow>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {/* ISMULTIPLE FALSE */}
                                                            <label
                                                              className="css_flex"
                                                              for={variation._id + '~' + option._id}
                                                            >
                                                              <MDBRadio
                                                                // onChange={(e) => {
                                                                //   attributeChange(e.target);
                                                                // }}
                                                                onClick={(e) => {
                                                                  // handleOptionSelectionCheckout(
                                                                  //   e,
                                                                  //   checkoutitem_index,
                                                                  //   option,
                                                                  //   variation,
                                                                  // );
                                                                  adjustCountRadio(
                                                                    checkoutitem_index,
                                                                    variationindex,
                                                                    variation,
                                                                    option,
                                                                    optionIndex,
                                                                    option,
                                                                    optionIndex,
                                                                    variation,
                                                                    variationindex,
                                                                  );
                                                                }}
                                                                className="variation_box"
                                                                name={
                                                                  checkoutitem?.orders[0] +
                                                                  '' +
                                                                  variation._id
                                                                }
                                                                value={
                                                                  checkoutitem?.orders[0] +
                                                                  '~' +
                                                                  variation._id +
                                                                  '~' +
                                                                  option._id
                                                                }
                                                                id={
                                                                  variation._id + '~' + option._id
                                                                }
                                                                checked={option.selected == 'Y'}
                                                                // label={option.name}
                                                              />
                                                              <div className="form-check-label">
                                                                {option.name}
                                                              </div>
                                                              <MDBCol className="attribPriceCol">
                                                                <h5 className="attribPrice">
                                                                  DKK {option.additionalPrice}{' '}
                                                                </h5>
                                                              </MDBCol>
                                                            </label>
                                                          </>
                                                        )}
                                                      </MDBRow>
                                                    </MDBCardText>
                                                  </MDBCardBody>
                                                </MDBCard>
                                              </MDBCol>
                                              {/* HAS ITEMS THAT BELONGS TO OPTIONS ABOVE */}
                                              {checkoutitem?.orders?.[7]?.map?.(
                                                (variationx, variationxindex2) => {
                                                  return variationx.hasOwnProperty('belongsto') &&
                                                    variationx.belongsto == option._id ? (
                                                    <>
                                                      <MDBContainer
                                                        style={{
                                                          display: parentSelected(
                                                            checkoutitem_index,
                                                            variationx.belongsto,
                                                          )
                                                            ? 'block'
                                                            : 'none',
                                                        }}
                                                        className={`variation_box_in ${variationx._id}`}
                                                        fluid
                                                      >
                                                        <div className="variationx_name">
                                                          {variationx.name}{' '}
                                                          {variation.attributesRequired && (
                                                            <span className="requiredTxt">
                                                              (Required)
                                                            </span>
                                                          )}
                                                        </div>
                                                        {variationx?.options?.map?.(
                                                          (optionx, optionxIndex) => (
                                                            <>
                                                              <label
                                                                className="row detailTabCartVariantOptions css_flex cursor-pointer"
                                                                for={
                                                                  variationx._id + '~' + optionx._id
                                                                }
                                                              >
                                                                <MDBCol className="variationxoption">
                                                                  <MDBRadio
                                                                    checked={
                                                                      optionx.selected == 'Y'
                                                                    }
                                                                    onClick={(e) => {
                                                                      adjustCountRadio(
                                                                        checkoutitem_index,
                                                                        variationindex,
                                                                        variation,
                                                                        option,
                                                                        optionIndex,
                                                                        optionx,
                                                                        optionxIndex,
                                                                        variationx,
                                                                        variationxindex2,
                                                                      );
                                                                    }}
                                                                    name={
                                                                      checkoutitem?.orders[0] +
                                                                      '' +
                                                                      variationx._id
                                                                    }
                                                                    id={
                                                                      variationx._id +
                                                                      '~' +
                                                                      optionx._id
                                                                    }
                                                                    value={
                                                                      checkoutitem?.orders[0] +
                                                                      '~' +
                                                                      variationx._id +
                                                                      '~' +
                                                                      optionx._id
                                                                    }
                                                                    label={optionx.name}
                                                                  />
                                                                </MDBCol>
                                                                <MDBCol className="variationxoption2">
                                                                  <h5 className="variationxoption2price">
                                                                    DKK {optionx.Price}
                                                                  </h5>
                                                                </MDBCol>
                                                              </label>
                                                            </>
                                                          ),
                                                        )}
                                                      </MDBContainer>
                                                    </>
                                                  ) : null;
                                                },
                                              )}
                                            </MDBRow>
                                          ))}
                                        </div>
                                      </>
                                    ) : null,
                                  )}
                                </>
                              </MDBAccordionItem>
                            </MDBAccordion>
                          </>
                        ) : null}

                        <MDBRow className="delete_toggle_row">
                          <MDBCol>
                            <MDBBtn
                              onClick={() => removeFromCart(checkoutitem.orders[0])}
                              outline
                              color="black"
                              className="remove_cart"
                              type="button"
                            >
                              Slet vare
                            </MDBBtn>
                          </MDBCol>
                          <MDBCol>
                            <MDBRow className="modal_card_counter">
                              <MDBCol className="detail_modal_counter not_add_cart">
                                <MDBBtn
                                  onClick={() =>
                                    decrementCounter2(
                                      checkoutitem.orders[0] + 'index' + checkoutitem_index,
                                    )
                                  }
                                  outline
                                  color="black"
                                  className="detail_modal_inc_dec_btn"
                                  type="button"
                                >
                                  <MDBIcon fas icon="minus-circle" size="2x" />
                                </MDBBtn>
                                <MDBInput
                                  id={checkoutitem.orders[0] + 'index' + checkoutitem_index}
                                  className="number_toggle_cart"
                                  min="1"
                                  max="99"
                                  type="number"
                                  value={checkoutitem.orders[5]}
                                  placeholder=""
                                />
                                <MDBBtn
                                  onClick={() =>
                                    incrementCounter2(
                                      checkoutitem.orders[0] + 'index' + checkoutitem_index,
                                    )
                                  }
                                  outline
                                  color="black"
                                  className="detail_modal_inc_dec_btn"
                                  type="button"
                                >
                                  <MDBIcon fas icon="plus-circle" size="2x" />
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                      </MDBRow>
                    ))}
                  </div>

                  <MDBRow className="modal_confirm_order">
                    <MDBCol className="modal_confirm_order_subtotal">
                      <span>Subtotal {totalOrders.length} vare(r)</span>
                    </MDBCol>
                    <MDBCol className="modal_confirm_order_subtotal_price">
                      <span>DKK {totalPrice}</span>
                    </MDBCol>
                  </MDBRow>
                </>
              </MDBModalBody>
              <MDBModalFooter className="checkout_footer">
                {isGuest ? (
                  <>
                    {/* firstname */}
                    <p className="confirm_order_labels my_contact">Fornavn</p>
                    <MDBInputGroup className="mycontact mb-3">
                      <input
                        type="text"
                        className="_input"
                        onChange={(e) => {
                          setGuestInfo({
                            ...guestInfo,
                            firstname: e.target.value,
                          });
                        }}
                        value={guestInfo.firstname || ''}
                        tabIndex={'1'}
                      />
                      {!guestInfoValidation.firstname ? (
                        ''
                      ) : (
                        <>
                          <p className="validation_error">Indtast venligst et gyldigt Fornavn.</p>
                        </>
                      )}
                    </MDBInputGroup>

                    {/* lastname */}
                    <p className="confirm_order_labels my_contact">Efternavn</p>
                    <MDBInputGroup className="mycontact mb-3">
                      <input
                        type="text"
                        className="_input"
                        onChange={(e) => {
                          setGuestInfo({
                            ...guestInfo,
                            lastname: e.target.value,
                          });
                        }}
                        value={guestInfo.lastname || ''}
                        tabIndex={'2'}
                      />
                      {!guestInfoValidation.lastname ? (
                        ''
                      ) : (
                        <>
                          <p className="validation_error">Indtast venligst et gyldigt Efternavn.</p>
                        </>
                      )}
                    </MDBInputGroup>

                    {/* email */}
                    <p className="confirm_order_labels my_contact">E-mail</p>
                    <MDBInputGroup className="mycontact mb-3">
                      <input
                        type="email"
                        className="_input"
                        value={guestInfo.email || ''}
                        onChange={(e) => {
                          setGuestInfo({
                            ...guestInfo,
                            email: e.target.value,
                          });
                          if (!validateEmail(e.target.value)) {
                            setGuestInfoValidation({
                              ...guestInfoValidation,
                              email: true,
                            });
                          } else {
                            setGuestInfoValidation({
                              ...guestInfoValidation,
                              email: false,
                            });
                          }
                        }}
                        tabIndex={'3'}
                      />
                      {!guestInfoValidation.email ? (
                        ''
                      ) : (
                        <>
                          <p className="validation_error">Indtast venligst et gyldigt e-mail.</p>
                        </>
                      )}
                    </MDBInputGroup>
                  </>
                ) : null}

                <p className="confirm_order_labels my_contact">Mobilnummer</p>
                <MDBInputGroup className="mycontact mb-3">
                  <PhoneInput
                    country={'dk'}
                    value={final_contact}
                    onChange={(phone) => {
                      contactChange(phone);
                    }}
                    tabIndex={'4'}
                    className="phoneinput"
                  />
                  {validPhone ? (
                    ''
                  ) : (
                    <>
                      <p className="validation_error">Indtast venligst et gyldigt telefonnummer.</p>
                    </>
                  )}
                </MDBInputGroup>
                {isDineIn ? (
                  <>
                    <p className="confirm_order_labels my_table">Bordnummer</p>
                    <MDBInputGroup className="mytable mb-3">
                      <Select
                        placeholder="Vælg bordnummer"
                        options={tableNumbers}
                        value={final_table || null}
                        onChange={(table) => tableChange(table)}
                        tabIndex={'5'}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            border: '1px solid #aaa',
                            boxShadow: 'none',
                            '&:hover': {
                              border: '1px solid aaa',
                            },
                            borderRadius: '30px',
                            padding: '5px',
                          }),
                        }}
                      />
                      {validTable ? (
                        ''
                      ) : (
                        <>
                          <p className="validation_error">Vælg venligst et gyldigt bordnummer.</p>
                        </>
                      )}
                    </MDBInputGroup>
                  </>
                ) : null}
                <p className="confirm_order_labels">Noter</p>
                <MDBInputGroup className="mb-3">
                  <textarea
                    rows="4"
                    cols="70"
                    value={final_notes}
                    onChange={(e) => notesChange(e.target.value)}
                    className="notes_text_area"
                    placeholder="Skriv evt. ønsker eller kommentar..."
                    tabIndex={'6'}
                  />
                </MDBInputGroup>

                <RenderMobileDineInTakeoutControl
                  mainDivClassName={'dinein_takeout dinein_takeout_mobile_only'}
                />

                <MDBBtn className="placeorder" onClick={proceedCheckout}>
                  Total: DKK {totalPrice} - Gå til betaling
                </MDBBtn>
              </MDBModalFooter>
            </>
          ) : null}
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default ReviewModal;
