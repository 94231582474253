import React from 'react';
import throttle from 'lodash/throttle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { MDBIcon, MDBBtn } from 'mdb-react-ui-kit';

const tabHeight = 69;
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 30,
      width: '100%',
      // backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    height: tabHeight,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  indicator: {
    padding: theme.spacing(1),
  },
  demo2: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
}));

/******* This is the scroll spy magic */
/*
Credits: Material UI
Source: 
https://github.com/mui-org/material-ui/blob/404c2ba16816f5c7ab7d8b2caf6bbc3d2218b820/docs/src/modules/utils/textToHash.js
*/
const makeUnique = (hash, unique, i = 1) => {
  const uniqueHash = i === 1 ? hash : `${hash}-${i}`;

  if (!unique[uniqueHash]) {
    unique[uniqueHash] = true;
    return uniqueHash;
  }

  return makeUnique(hash, unique, i + 1);
};

const textToHash = (text, unique = {}) => {
  return makeUnique(
    encodeURI(
      text
        .toLowerCase()
        .replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>|&#39;/g, '')
        // eslint-disable-next-line no-useless-escape
        .replace(/[!@#\$%\^&\*\(\)=_\+\[\]{}`~;:'"\|,\.<>\/\?\s]+/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, ''),
    ),
    unique,
  );
};
const noop = () => {};

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = React.useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay],
  );

  React.useEffect(() => {
    if (throttledCallback === noop) return undefined;

    window.addEventListener('scroll', throttledCallback);
    return () => {
      window.removeEventListener('scroll', throttledCallback);
      throttledCallback.cancel();
    };
  }, [throttledCallback]);
}

function ScrollSpyTabs(props) {
  const [activeState, setActiveState] = React.useState(null);
  const {
    tabsInScroll,
    hasHigherCat,
    isHigherCatOverflow,
    isHigherCat,
    selectHigherCat,
    isMobileView,
  } = props;

  let itemsServer = tabsInScroll.map((tab) => {
    const hash = textToHash(tab.text + '' + tab._id);
    return {
      icon: tab.icon || '',
      text: tab.text,
      component: tab.component,
      hash: hash,
      node: document.getElementById(hash),
      tab: tab,
      ...tab,
    };
  });

  const itemsClientRef = React.useRef([]);
  React.useEffect(() => {
    itemsClientRef.current = itemsServer;
  }, [itemsServer]);

  const clickedRef = React.useRef(false);
  const unsetClickedRef = React.useRef(null);
  const findActiveIndex = React.useCallback(() => {
    // set default if activeState is null
    if (activeState === null) setActiveState(itemsServer[0].hash);

    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) return;

    let active;
    for (let i = itemsClientRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 0) {
        active = { hash: null };
        break;
      }

      const item = itemsClientRef.current[i];

      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop + document.documentElement.clientHeight / 8 + tabHeight
      ) {
        active = item;
        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, itemsServer]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(itemsServer.length > 0 ? findActiveIndex : null, 166);

  const handleClick = (hash, item) => () => {
    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);

      if (window)
        window.scrollTo({
          top: document.getElementById(hash).getBoundingClientRect().top + window.pageYOffset,
          behavior: 'smooth',
        });
    }
  };

  const handleClick2 = (currentActive, serverItems, direction) => {
    if (direction === 'up') {
      if (currentActive === null) {
        setActiveState(serverItems[0].hash);
      } else {
        let currentIndex = serverItems.findIndex((o) => o.hash === currentActive);
        currentIndex++;
        if (serverItems.length >= currentIndex) {
          document.getElementById('category_' + serverItems[currentIndex].hash).click();
          // setActiveState(serverItems[currentIndex].hash);
          // if (window)
          //   window.scrollTo({
          //     top:
          //       document.getElementById(serverItems[currentIndex].hash).getBoundingClientRect()
          //         .top + window.pageYOffset,
          //     behavior: 'smooth',
          //   });
        } else {
          document.getElementById('category_' + serverItems[currentIndex].hash).click();
          // setActiveState(serverItems[serverItems.length].hash);
          // if (window)
          //   window.scrollTo({
          //     top:
          //       document
          //         .getElementById(serverItems[serverItems.length].hash)
          //         .getBoundingClientRect().top + window.pageYOffset,
          //     behavior: 'smooth',
          //   });
        }
      }
    } else {
      if (currentActive === null) {
        setActiveState(serverItems[0].hash);
      } else {
        let currentIndex = serverItems.findIndex((o) => o.hash === currentActive);
        currentIndex--;
        if (currentIndex >= 0) {
          document.getElementById('category_' + serverItems[currentIndex].hash).click();
          // setActiveState(serverItems[currentIndex].hash);
          // if (window)
          //   window.scrollTo({
          //     top:
          //       document.getElementById(serverItems[currentIndex].hash).getBoundingClientRect()
          //         .top + window.pageYOffset,
          //     behavior: 'smooth',
          //   });
        } else {
          document.getElementById('category_' + serverItems[currentIndex].hash).click();
          // setActiveState(serverItems[0].hash);
          // if (window)
          //   window.scrollTo({
          //     top:
          //       document.getElementById(serverItems[0].hash).getBoundingClientRect().top +
          //       window.pageYOffset,
          //     behavior: 'smooth',
          //   });
        }
      }
    }
  };

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    [],
  );

  const classes = useStyles();

  const ButtonGroup = (props) => {
    const { currentActive, serverItems, handleClick2 } = props;
    return (
      <div className="carousel-button-group">
        <MDBBtn
          onClick={() => props.handleClick2(currentActive, serverItems, 'down')}
          outline
          color="black"
          className="left_btn_tab left_btn_tab_float left-btn-cat"
          type="button"
          // data-mdb-ripple-duration="0"
        >
          <MDBIcon fas size="2x" icon="chevron-left" />
        </MDBBtn>
        <MDBBtn
          onClick={() => props.handleClick2(currentActive, serverItems, 'up')}
          outline
          color="black"
          className="right_btn_tab right_btn_tab_float right-btn-cat"
          type="button"
          // data-mdb-ripple-duration="0"
        >
          <MDBIcon fas size="2x" icon="chevron-right" />
        </MDBBtn>
      </div>
    );
  };

  return (
    <div style={{ marginTop: isMobileView ? 0 : '-53px' }}>
      <nav
        className={`${classes.demo2} _scrollspy ${hasHigherCat ? 'hashighercat' : 'nohighercat'} ${
          isHigherCatOverflow ? 'ishighercatoverflow' : ''
        }`}
      >
        <ButtonGroup
          currentActive={activeState}
          serverItems={itemsServer}
          handleClick2={handleClick2}
        />
        <StyledTabs value={activeState ? activeState : itemsServer?.[0]?.hash}>
          {itemsServer.map((item2) => (
            <StyledTab
              key={item2.hash}
              id={'category_' + item2.hash}
              label={
                !isHigherCat ? (
                  <div>{item2.text}</div>
                ) : (
                  <div>
                    <span className="highercattitle">{item2.text}</span>
                    <span className="highercattimes">
                      {item2?.start} - {item2?.end}
                    </span>
                  </div>
                )
              }
              onClick={handleClick(item2.hash, item2)}
              value={item2.hash}
            />
          ))}
        </StyledTabs>
        <div className={classes.indicator} />
      </nav>

      <div className="container">
        {itemsServer.map((item1) => (
          <article id={item1.hash} key={item1.text}>
            {item1.component}
          </article>
        ))}
      </div>
    </div>
  );
}

export default ScrollSpyTabs;
