import React, { useEffect, useState, createContext, useContext } from 'react';
import axios from 'axios';
import configData from '../config.json';

export const OrderContext = createContext();

export function OrderWrapper({ children }) {
  const [pendingOrder, setPendingOrder] = useState({});
  const [signupModal, setSignupModal] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [guestId, setGuestId] = useState('');
  const [reviewModal, setReviewModal] = useState(false);
  const [shouldShowReview, setShouldShowReview] = useState(false);
  const [lastOrderId, setLastOrderId] = useState('');

  useEffect(() => {
    checkPendingOrder();

    checkAutoAcceptModalParams();
  }, []);

  const checkAutoAcceptModalParams = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('showAcceptModal')) {
      const url = new URL(window.location.href);
      url.searchParams.delete('showAcceptModal');
      window.history.pushState({}, '', url.toString());
      const lastorderid = params.get('lastorder');

      if (lastorderid) {
        setLastOrderId(lastorderid);
      }
    }
  };

  const checkPendingOrder = () => {
    let pending = localStorage.getItem('hw_pending_order');
    if (pending) {
      pending = JSON.parse(pending);
      setPendingOrder({ ...pending });
      return;
    }

    //check last order
    let userProfile = localStorage.getItem('user_profile');
    let guestId = localStorage.getItem('guest-uui');

    const params = new URLSearchParams(window.location.search);
    const lastorderid = params.get('lastorder');

    if (userProfile) {
      userProfile = JSON.parse(userProfile);
      axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/order/getlastorder${
          lastorderid ? `?orderid=${lastorderid}` : ''
        }`,
        data: null,
        headers: {
          Authorization: `Bearer ${userProfile.token}`,
        },
      }).then(async (response) => {
        if (response?.data) {
          const info = await axios({
            method: 'GET',
            url: `${configData.SERVER_URL}/order/details?id=${response?.data}`,
            data: null,
            headers: {
              Authorization: `Bearer ${userProfile.token}`,
            },
          });

          const lastOrder = {
            ...info.data,
            status: '',
          };

          localStorage.setItem('hw_pending_order', JSON.stringify(lastOrder));
          setPendingOrder({ ...lastOrder });
          //clear lastorderid params
          if (lastorderid) {
            const { protocol, host, pathname } = window.location;
            if (window.history.pushState) {
              var newurl = protocol + '//' + host + pathname + '';
              window.history.pushState({ path: newurl }, '', newurl);
            }
          }
        }
      });
    } else if (guestId) {
      axios({
        method: 'GET',
        url: `${configData.SERVER_URL}/order/getlastorder/guest?id=${guestId}${
          lastorderid ? `&orderid=${lastorderid}` : ''
        }`,
        data: null,
      }).then(async (response) => {
        if (response?.data) {
          const info = await axios({
            method: 'GET',
            url: `${configData.SERVER_URL}/order/details?id=${response?.data}`,
            data: null,
          });
          const lastOrder = {
            ...info.data,
            status: '',
          };
          localStorage.setItem('hw_pending_order', JSON.stringify(lastOrder));
          setPendingOrder({ ...lastOrder });
          //clear lastorderid params
          if (lastorderid) {
            const { protocol, host, pathname } = window.location;
            if (window.history.pushState) {
              var newurl = protocol + '//' + host + pathname + '';
              window.history.pushState({ path: newurl }, '', newurl);
            }
          }
        }
      });
    }
  };

  return (
    <OrderContext.Provider
      value={{
        pendingOrder,
        signupModal,
        setSignupModal,
        isGuest,
        setIsGuest,
        guestId,
        setGuestId,
        reviewModal,
        setReviewModal,
        shouldShowReview,
        setShouldShowReview,
        lastOrderId,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export function useOrderContext() {
  return useContext(OrderContext);
}
